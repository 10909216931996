<template>
  <div class="communityDetails">
    <div class="common-box">
      <div class="head">
        <div class="big-title">{{resourcesData.title}}</div>
        <div class="middle">
          <span class="tag">{{resourcesData.projectType}}</span>
          <span class="tagTitle"> {{objStrToObj(resourcesData.province)}} # {{resourcesData.industry}}</span>
        </div>
      </div>
      <div class="publisherBox">
        <div class="publisherTitle">发布人信息</div>
        <div class="info-list">
          <div class="info-item">
            <div class="info-item-title"> 姓名 </div>
            <div class="info-item-content"> {{resourcesData.name}} </div>
          </div>
          <div class="info-item">
            <div class="info-item-title"> 职位 </div>
            <div class="info-item-content"> {{resourcesData.workPosition ? resourcesData.workPosition : ''}} </div>
          </div>
          <div class="info-item">
            <div class="info-item-title"> 公司/项目 </div>
            <div class="info-item-content"> {{resourcesData.companyName}} </div>
          </div>
        </div>
      </div>
    </div>

    <div class="common-box">
      <div class="head">
        <div class="publisherTitle">项目简介</div>
        <div class="content">
          <div class="main-text">{{resourcesData.projectIntroduction}}</div>
        </div>
      </div>
    </div>

    <div class="common-box">
      <div class="head">
        <div class="publisherTitle">项目需求</div>
        <div class="content">
          <div class="main-text">{{resourcesData.projectDemand}}</div>
        </div>
      </div>
    </div>

    <div class="common-box">
      <div class="head">
        <div class="publisherTitle">项目图片</div>
        <div class="content">
          <div v-for="(item,index) in imgList" :key="index" class="img-container">
            <el-image :src="item" fit="cover" style="width: 100%;height: 100%;"></el-image>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn">
      <el-popover placement="top" title="请扫码在小程序内进行操作" width="220" trigger="click">
        <div style="text-align: center;">
          <el-image fill="contain" :src="require('@/assets/img/mobile/codeImg_2.png')" style="width: 10.9375rem;height: 10.9375rem;"></el-image>
        </div>
        <div class="longCapsuleBtn" slot="reference">与TA沟通</div>
      </el-popover>
    </div>




  </div>
</template>

<script>
  import {
    request
  } from '@/utils/request';
  export default {
    data() {
      return {
        imgList: [],
        id:0,
        resourcesData: {}
      };
    },
    created() {
      this.id = this.$route.query.id;
      this.resourceDetails();
    },
    methods: {
      resourceDetails(){
        let url = 'https://xinke.thinkervc.cn/api/applet/institutions/getCommunityByOrderId';
        url += '?id=' + this.id;
        request(url).then(res => {
          if (res.code === 200) {
            this.resourcesData = res.data;
            this.imgList = res.data.imgUrls.split(',');
          }
        })
      },
      objStrToObj(objStr) {
        if (objStr) {
          let arr = objStr.split(',')
          return arr[0]
        }
        return ''
      }
    },
    components: {}
  };
</script>

<style scoped lang="scss">
  .communityDetails {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .common-box {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 0 0 11px 0 rgb(94 94 94 / 16%);
    padding: 0 19px;
    margin-bottom: 9px;
  }

  .head {
    padding: 19px 0;
  }

  .big-title {
    font-size: 22px;
    font-weight: bold;
  }

  .middle {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: 8px 0;
  }

  .tag {
    display: inline-block;
    color: rgb(81, 80, 255);
    background-color: rgb(212, 212, 255);
    padding: 0 13px;
    height: 30px;
    line-height: 30px;
    border: 1px solid rgb(161, 161, 255);
    border-radius: 3px;
    font-size: 13px;
  }

  .tagTitle {
    display: inline-block;
    color: #909399;
    margin-left: 9px;
    line-height: 30px;
  }

  .publisherBox {
    color: #606266;
    padding: 19px 0;
  }

  .publisherTitle {
    color: #303133;
    font-weight: bold;
  }

  .info-item {
    display: flex;
    padding: 4px 0;
  }

  .info-item-title {
    min-width: 71px;
    text-align: left;
    font-weight: bold;
    color: #303133;
  }

  .info-item-content {
    margin-left: 19px;
  }

  .content {
    padding: 9px;
    color: #606266;
  }

  .main-text {
    text-indent: 2rem;
  }
  .img-container {
      width: 100%;
      height: 256px;
      margin-bottom: 19px;
      overflow: hidden;
  }
  .bottom-btn {
    padding: 0 19px;
    margin-top: 11px;
  }
  .longCapsuleBtn {
      width: 100%;
      height: 42px;
      line-height: 42px;
      margin: 23px auto;
      color: #FFFFFF;
      text-align: center;
      border-radius: 21px;
      background-image: linear-gradient(0deg, #3E3CE9 9%, #8F8FFF 91%);
  }
</style>
